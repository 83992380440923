import axios from '@/axios'
import qs from 'qs'
export default {
  isRegistered: false,
  namespaced: true,
  state: {
    partners: [],
    totalItems: 0,
    partner: {
      name: null,
      locations: [{ country: null, cities: [''] }],
      marketsCountry: [''],
      videoUrl: '',
      deckUrl: '',
      agencyPageUrl: '',
      keyContacts: [
        {
          firstname: '',
          lastname: '',
          role: '',
          phone: '',
          email: '',
          linkedin: ''
        }
      ],
      coreSkill: null,
      detailledSkills: [],
      expertServicePortfolios: [],
      sectorExperiences: [],
      hiringSectors: []
    }
  },
  mutations: {
    SET_PARTNER (state, partner) {
      state.partner = partner
    },
    SET_PARTNERS (state, partnersData) {
      state.partners = partnersData['hydra:member']
      state.totalItems = partnersData['hydra:totalItems']
    },
    ADD_PARTNER (state, partner) {
      state.partners.push(partner)
      state.totalItems++
    },
    UPDATE_PARTNER (state, partner) {
      const partnerIndex = state.partners.findIndex((p) => p.id === partner.id)
      state.partners[partnerIndex] = partner
    },
    REMOVE_PARTNER (state, partnerId) {
      const partnerIndex = state.partners.findIndex((g) => g.id === partnerId)
      state.partners.splice(partnerIndex, 1)
      state.totalItems--
    }
  },
  actions: {
    fetchPartnerByUserId ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        axios.get(`/users/${userId}/partner`)
          .then((response) => {
            commit('SET_PARTNER', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    newPartner ({ commit }, partner) {
      return new Promise((resolve, reject) => {
        axios.post('/partners', partner)
          .then((response) => {
            commit('ADD_PARTNER', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchPartners ({ commit, rootGetters }, payload) {
      if (rootGetters.hasCancelToken('fetchPartners')) {
        rootGetters.cancelToken('fetchPartners').cancel()
      }

      commit('SET_CANCEL_TOKEN', 'fetchPartners', {root: true})

      return new Promise((resolve, reject) => {
        let url = '/partners'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url, {
          cancelToken: rootGetters.cancelToken('fetchPartners').token
        }).then((response) => {
          commit('SET_PARTNERS', response.data)
          resolve(response)
        })
          .catch((error) => { reject(error) })
      })
    },
    deletePartner ({ commit }, partnerId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/partners/${partnerId}`)
          .then((response) => {
            commit('REMOVE_PARTNER', partnerId)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updatePartner ({ commit }, partner) {
      return new Promise((resolve, reject) => {
        axios.put(`/partners/${partner.id}`, partner)
          .then((response) => {
            commit('UPDATE_PARTNER', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    getPartner (state) {
      return state.partner
    }
  }
}
