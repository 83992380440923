<template>
  <div ref="pageHome" class="container flex flex-col page-home">
    <h1 class="mb-2 self-center text-1xl md:text-4xl">{{ $t("home.title") }}</h1>
    <p class="mb-12 self-center text-xl md:text-3xl">{{ $t("home.subtitle") }}</p>
    <div class="flex flex-col lg:justify-center lg:items-center lg:mx-auto">

      <div class="flex flex-col sm:flex-col justify-evenly items-center mb-10">
        <div class="flex flex-col items-center md:flex-row mb-4 space-y-4 md:space-y-0 md:space-x-5">
          <vs-select
            class="w-full md:w-56 px-16 md:px-0"
            :label="$t('partner.form.fieldset.partner.location')"
            @change="executeFilters"
            v-model="locationQuery"
            name="partnerlocation_country"
            :placeholder="$t('home.form.input.select.placeholder')"
            multiple
          >
          <vs-select-item class="ml-4" value="" :text="$t('home.form.input.select.placeholder')"/>
            <vs-select-group
              v-for="(location, index) in locations"
              :key="index"
              :title="location.name"
              :value="location.id"
            >
              <vs-select-item
                v-for="city in location.cities"
                :key="city.id"
                :value="city.id"
                :text="city.name"
              />
            </vs-select-group>
          </vs-select>
          <vs-select
            class="w-screen md:w-56 px-16 md:px-0"
            :label="$t('partner.form.fieldset.detailled_skills')"
            @change="executeFilters"
            v-model="detailledSkillsQuery"
            name="detailled_skills"
            :placeholder="$t('home.form.input.select.placeholder')"
            multiple
            autocomplete
          >
            <vs-select-item value="" :text="$t('home.form.input.select.placeholder')"/>
            <vs-select-item
              v-for="detailledSkill in detailledSkills"
              :key="detailledSkill.id"
              :value="detailledSkill.id"
              :name="detailledSkill.name"
              :text="detailledSkill.name"
            />
          </vs-select>
            <vs-select
            class="w-screen md:w-56 px-16 md:px-0"
            :label="$t('partner.form.fieldset.expert_services')"
            @change="executeFilters"
            v-model="portfolioOfServicesQuery"
            name="expert_service_portfolios"
            :placeholder="$t('home.form.input.select.placeholder')"
            autocomplete
            multiple
          >
            <vs-select-item value="" :text="$t('home.form.input.select.placeholder')"/>
            <vs-select-item
              v-for="expertService in expertServices"
              :key="expertService.id"
              :value="expertService.id"
              :text="expertService.name"
            />
          </vs-select>
        </div>
        <div v-if="isAdvancedSearch" class="flex flex-col md:flex-row md:mb-3 md:justify-center lg:items-end space-y-4 md:space-y-0 md:space-x-5">
          <div class="flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-5 lg:space-y-0 lg:space-x-5">
            <div class="flex flex-col space-y-3 lg:flex-row lg:space-x-4 lg:space-y-0">
              <vs-select
                class="w-screen md:w-56 px-16 md:px-0"
                :label="$t('partner.form.fieldset.markets_worked')"
                @change="executeFilters"
                v-model="marketsWorkedQuery"
                name="marketsworked_country"
                :placeholder="$t('home.form.input.select.placeholder')"
                multiple
                autocomplete
              >
              <vs-select-item value="" :text="$t('home.form.input.select.placeholder')"/>
              <vs-select-item
                v-for="market in marketsCountry"
                :key="market.id"
                :value="market.id"
                :name="market.name"
                :text="market.name"
              />
              </vs-select>
              <vs-select
                class="w-screen md:w-56 px-16 md:px-0"
                :label="$t('partner.form.input.core_skill')"
                @change="executeFilters"
                v-model="coreSkillQuery"
                name="core_skill"
                :placeholder="$t('home.form.input.select.placeholder')"
                autocomplete
              >
                <vs-select-item value="" :text="$t('home.form.input.select.placeholder')"/>
                <div v-for="coreSkill in coreSkills" :key="coreSkill.id">
                  <vs-select-item
                    :value="coreSkill.id"
                    :name="coreSkill.name"
                    :text="coreSkill.name"
                  />
                </div>
              </vs-select>
            </div>
            <div class="flex flex-col lg:flex-row space-y-3 lg:space-x-4 lg:space-y-0">
              <vs-select
              class="w-screen md:w-56 px-16 md:px-0"
              :label="$t('partner.form.fieldset.sectors_exp')"
              @change="executeFilters"
              v-model="sectorExperiencesQuery"
              name="sectors_exp"
              :placeholder="$t('home.form.input.select.placeholder')"
              autocomplete
              multiple
            >
              <vs-select-item value="" :text="$t('home.form.input.select.placeholder')"/>
              <vs-select-item
                v-for="experienceSector in experienceSectors"
                :key="experienceSector.id"
                :value="experienceSector.id"
                :text="experienceSector.name"
              />
              </vs-select>
              <vs-select
                class="w-screen md:w-56 px-16 md:px-0"
                :label="$t('partner.form.fieldset.hiring_sectors')"
                @change="executeFilters"
                v-model="hiringSectorsQuery"
                name="hiring_sectors"
                :placeholder="$t('home.form.input.select.placeholder')"
                autocomplete
                multiple
              >
                <vs-select-item value="" :text="$t('home.form.input.select.placeholder')"/>
                <vs-select-item
                  v-for="hiringSector in hiringSectors"
                  :key="hiringSector.id"
                  :value="hiringSector.id"
                  :text="hiringSector.name"
                />
              </vs-select>
            </div>
          </div>
          <div class="w-screen md:w-56 px-16 md:px-0">
            <vs-input
            class="w-full lg:pt-4"
            :label="$t('home.form.input.skill_keyword')"
            :placeholder="$t('home.form.input.search.placeholder')"
            v-model="searchQuery"
            @input="executeFilters"
            name="searchQuery"
            />
          </div>
        </div>
        <div class="w-full flex justify-between px-8 md:px-16 lg:px-0 md:mt-0" :class="isAdvancedSearch ? 'mt-3' : 'mt-0'">
          <div class="flex items-center space-x-1">
            <span v-if="!isAdvancedSearch" class="material-icons clear-icon">add</span>
            <span v-else class="material-icons clear-icon">remove</span>
            <button
              @click="advancedSearch"
              class="btn-reset-filters hover:underline cursor-pointer text-xs md:text-sm"
              >{{ $t('home.form.button.advanced_search') }}
            </button>
          </div>
          <div class="flex items-center space-x-1">
            <span class="material-icons clear-icon">clear</span>
            <button
              @click="resetFilters"
              class="btn-reset-filters hover:underline cursor-pointer text-xs md:text-sm"
              >{{ $t('home.form.button.reset') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!localPartners.length">
      <div class="mx-auto">
        <p>{{ $t('home.search.not_found') }}</p>
      </div>
    </template>

    <vs-row vs-justify="center" class="w-full mx-auto">
      <vs-col
        v-for="partner in localPartners"
        :key="partner.id"
        type="flex"
        class="w-full my-2 md:w-1/2 xl:w-1/3"
      >
        <vs-card class="card mx-2 w-auto" fixedHeight>
          <div slot="header" class="px-5 py-3">
            <vs-row>
              <h3 class="flex-grow w-full">
                {{ partner.name }}
                <vs-button v-if="$acl.check('superadmin')" @click="confirmDeletePartner(partner)" class="absolute top-0 right-0 my-4 mx-0" color="danger" type="flat" icon="delete_forever"></vs-button>
              </h3>
              <div @click="addFilterOnCoreSkillSearch(partner.coreSkill.id)" class="cursor-pointer">
                <vs-chip transparent color="primary mt-3"
                         class="shadow-md">
                  {{ partner.coreSkill.name }}
                </vs-chip>
              </div>

              <div v-for="(detail, index) in partner.detailledSkills"
                   :key="index"
                   @click="addFilterOnDetailledSkillsSearch(detail.id)"
                   class="cursor-pointer">
                <vs-chip
                  color="secondary"
                  class="mt-3 shadow-md">
                      {{ detail.name }}
                </vs-chip>
              </div>
            </vs-row>
          </div>
          <div class="card-content">
            <div class="text-black mx-2">
              <div class="mx-3">
                <div class="text-sm font-bold">
                  {{ $t("partner.form.fieldset.partner.location") }}
                </div>
                <span
                  v-for="(location, index) in locationRender(partner.locations)"
                  :key="index"
                  class="block font-medium mb-2 text-sm"
                >
                  {{ location.country }} :
                  <span
                    v-for="(cities, indexCities) in location.cities"
                    :key="indexCities"
                    class="comma inline-flex font-light"
                  >
                    {{ cities }}
                  </span>
                </span>
              </div>
              <div class="card-category" v-if="partner.marketsCountry.length > 0">
                <div class="text-sm font-bold">
                  {{ $t("partner.form.fieldset.markets_worked") }}
                </div>
                <span
                  class="comma inline-flex text-sm"
                  v-for="(market, index) in partner.marketsCountry"
                  :key="index"
                >
                  {{ market.name }}
                </span>
              </div>

              <div class="card-category"
                v-if="
                  partner.expertServicePortfolios.length > 0 &&
                  partner.expertServicePortfolios[0]
                "
              >
                <div class="text-sm font-bold">
                  {{ $t("partner.form.fieldset.expert_services") }}
                </div>
                <span
                  class="comma inline-flex text-sm"
                  v-for="(expert, index) in partner.expertServicePortfolios"
                  :key="index"
                >
                  {{ expert.name }}
                </span>
              </div>

              <div class="card-category"
                v-if="
                  partner.sectorExperiences.length > 0 &&
                  partner.sectorExperiences[0]
                "
              >
                <div class="text-sm font-bold">
                  {{ $t("partner.form.fieldset.sectors_exp") }}
                </div>
                <span
                  class="comma inline-flex text-sm"
                  v-for="(sector, index) in partner.sectorExperiences"
                  :key="index"
                >
                  {{ sector.name }}
                </span>
              </div>

              <div class="card-category"
                v-if="
                  partner.hiringSectors.length > 0 && partner.hiringSectors[0]
                "
              >
                <div class="text-sm font-bold">
                  {{ $t("partner.form.fieldset.hiring_sectors") }}
                </div>
                <span
                  class="comma inline-flex text-sm"
                  v-for="(hiring, index) in partner.hiringSectors"
                  :key="index"
                >
                  {{ hiring.name }}
                </span>
              </div>

              <div v-if="partner.videoUrl || partner.deckUrl || partner.agencyPageUrl" class="card-category">
                <div class="mb-3 text-sm font-bold">
                  {{ $t("partner.form.fieldset.presentation") }}
                </div>
                <div class="flex justify-around">
                  <span v-if="partner.videoUrl" class="text-sm cursor-pointer">
                    <a
                       :href="partner.videoUrl"
                       :title="partner.videoUrl"
                       target="_blank"
                       rel="noopener noreferrer"
                       class="presentation-item"
                    >
                      <feather-icon icon="YoutubeIcon" svgClasses="h-6 w-6" />
                      <p class="mt-1 font-medium" v-html="$t('partner.card.video_link')" />
                    </a>
                  </span>
                <span v-if="partner.deckUrl" class="text-sm cursor-pointer">
                  <a
                    :href="partner.deckUrl"
                    :title="partner.deckUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="presentation-item"
                  >
                    <feather-icon icon="FileTextIcon" svgClasses="h-6 w-6" />
                    <p class="mt-1 font-medium" v-html="$t('partner.card.deck_link')" />
                  </a>
                </span>
                <span v-if="partner.agencyPageUrl" class="text-sm cursor-pointer">
                  <a
                    :href="partner.agencyPageUrl"
                    :title="partner.agencyPageUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="presentation-item"
                  >
                    <feather-icon icon="HomeIcon" svgClasses="h-6 w-6" />
                    <p class="mt-1 font-medium" v-html="$t('partner.card.agency_link')" />
                  </a>
                </span>
                </div>
              </div>

              <div class="mt-5 -mx-1 contact"
                v-if="
                  partner.keyContacts &&
                  partner.keyContacts.length > 0 &&
                  partner.keyContacts[0].firstname
                "
              >
                <div class="text-sm">
                    <div
                      class="bg-white shadow-md my-4 py-4 px-4 rounded-lg"
                      v-for="(keycontact, index) in partner.keyContacts"
                      :key="index"
                    >
                      <div class="font-bold flex mb-5">
                        <vs-avatar class="flex-shrink-0" size="29px" />
                        <div class="relative ml-2 self-center">
                          {{ keycontact.firstname }} {{ keycontact.lastname }} -
                          <span class="font-medium" v-if="keycontact.role">{{ keycontact.role }}</span>
                        </div>

                      </div>

                      <a class="font-light flex items-center my-2" v-if="keycontact.email" :href="`mailto:${keycontact.email}`">
                        <feather-icon icon="MailIcon" svgClasses="h-4 w-4" class="ml-3 mr-2 font-black" />
                        {{ keycontact.email }}
                      </a>

                    <a class="font-light flex items-center my-2" v-if="keycontact.phone" :href="`tel:${keycontact.phone}`">
                        <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4" class="ml-3 mr-2 font-black" />
                      {{ keycontact.phone }}
                    </a>

                      <a class="font-light flex items-center my-2" v-if="keycontact.linkedin" :href="keycontact.linkedin" target="_blank" rel="noopener noreferrer">
                        <feather-icon icon="LinkedinIcon" svgClasses="h-4 w-4" class="ml-3 mr-2 font-black" />
                        {{ keycontact.linkedin }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- {{partners}}
    {{totalItems}} -->

  </div>
</template>

<script>

const cloneDeep = require('lodash.clonedeep')

import modulePartnerMixin from '@/store/partner/modulePartnerMixin'

export default {
  mixins: [modulePartnerMixin],
  metaInfo: {
    title: 'Home - Find a partner'
  },
  data () {
    return {
      localPartners: {},
      locations: [],
      marketsCountry: [],
      coreSkills: [],
      detailledSkills: [],
      experienceSectors: [],
      expertServices: [],
      hiringSectors: [],
      searchQuery: '',
      searchPartner: {},
      marketsWorkedQuery: '',
      searchMarketsWorked: {},
      locationQuery: '',
      searchLocation: {},
      coreSkillQuery: '',
      searchCoreSkill: {},
      detailledSkillsQuery: '',
      searchDetailledSkills: {},
      portfolioOfServicesQuery: '',
      searchPortfolioOfServices: {},
      sectorExperiencesQuery: '',
      searchSectorExperiencesQuery: {},
      hiringSectorsQuery: '',
      searchHiringSectorsQuery: {},
      partnerIdToDelete: null,
      isAdvancedSearch: false
    }
  },
  methods: {
    resetLocalPartners () {
      this.localPartners = cloneDeep(this.$store.state.partner.partners)
      const orderName = (a, b) => {
        return a.name.localeCompare(b.name)
      }
      return this.localPartners.map((p) => {
        const loc = p.locations
        loc.sort(orderName)
        p.marketsCountry.sort(orderName)
        p.detailledSkills.sort(orderName)
        p.expertServicePortfolios.sort(orderName)
        p.sectorExperiences.sort(orderName)
        p.hiringSectors.sort(orderName)
      })
    },
    executeFilters (withFetch) {
      if (typeof withFetch === 'undefined') {
        withFetch = true
      }

      this.resetLocalPartners()
      this.localPartners = this.localPartners.filter((partner) => {
        if (this.locationQuery.includes('')) {
          this.locationQuery = ''
        }
        if (this.locationQuery !== '') {
          for (const locationQuery of this.locationQuery) {
            if (partner.locations.findIndex((l) => l.id === locationQuery) === -1) {
              return false
            }
          }
        }

        if (this.marketsWorkedQuery.includes('')) {
          this.marketsWorkedQuery = ''
        }
        if (this.marketsWorkedQuery !== '') {
          for (const marketsWorkedQuery of this.marketsWorkedQuery) {
            if (partner.marketsCountry.findIndex((m) => m.id === marketsWorkedQuery) === -1) {
              return false
            }
          }
        }

        if (this.coreSkillQuery !== '' && partner.coreSkill.id !== this.coreSkillQuery) {
          return false
        }

        if (this.detailledSkillsQuery.includes('')) {
          this.detailledSkillsQuery = ''
        }
        if (this.detailledSkillsQuery !== '') {
          for (const detailledSkillsQuery of this.detailledSkillsQuery) {
            if (partner.detailledSkills.findIndex((d) => d.id === detailledSkillsQuery) === -1) {
              return false
            }
          }
        }

        if (this.portfolioOfServicesQuery.includes('')) {
          this.portfolioOfServicesQuery = ''
        }
        if (this.portfolioOfServicesQuery !== '') {
          for (const portfolioOfServicesQuery of this.portfolioOfServicesQuery) {
            if (partner.expertServicePortfolios.findIndex((p) => p.id === portfolioOfServicesQuery) === -1) {
              return false
            }
          }
        }

        if (this.sectorExperiencesQuery.includes('')) {
          this.sectorExperiencesQuery = ''
        }
        if (this.sectorExperiencesQuery !== '') {
          for (const sectorExperiencesQuery of this.sectorExperiencesQuery) {
            if (partner.sectorExperiences.findIndex((s) => s.id === sectorExperiencesQuery) === -1) {
              return false
            }
          }
        }

        if (this.hiringSectorsQuery.includes('')) {
          this.hiringSectorsQuery = ''
        }
        if (this.hiringSectorsQuery !== '') {
          for (const hiringSectorsQuery of this.hiringSectorsQuery) {
            if (partner.hiringSectors.findIndex((h) => h.id === hiringSectorsQuery) === -1) {
              return false
            }
          }
        }

        const searchQueryCapitalize =  this.searchQuery.charAt(0).toUpperCase() + this.searchQuery.slice(1)

        return !(searchQueryCapitalize !== ''
          && partner.name.search(searchQueryCapitalize) === -1
          && partner.locations.findIndex((l) => l.name.search(searchQueryCapitalize) !== -1) === -1
          && partner.marketsCountry.findIndex((m) => m.name.search(searchQueryCapitalize) !== -1) === -1
          && partner.coreSkill.name.search(searchQueryCapitalize) === -1
          && partner.detailledSkills.findIndex((d) => d.name.search(searchQueryCapitalize) !== -1) === -1
          && partner.expertServicePortfolios.findIndex((e) => e.name.search(searchQueryCapitalize) !== -1) === -1
          && partner.sectorExperiences.findIndex((s) => s.name.search(searchQueryCapitalize) !== -1) === -1
          && partner.hiringSectors.findIndex((h) => h.name.search(searchQueryCapitalize) !== -1) === -1)
      })
      this.updateFilters()

      if (withFetch) {
        this.fetchPartners()
      }
    },
    updateFilters () {
      this.locations = []
      this.marketsCountry = []
      this.coreSkills = []
      this.detailledSkills = []
      this.experienceSectors = []
      this.expertServices = []
      this.hiringSectors = []

      for (const partner of this.localPartners) {

        for (const location of partner.locations) {
          let locationIndex = this.locations.findIndex((l) => l.id === location.country.id)
          if (locationIndex === -1) {
            locationIndex = this.locations.push({id: location.country.id, name: location.country.name, cities: [] }) - 1
          }
          const cityIndex = this.locations[locationIndex].cities.findIndex((c) => c.id === location.id)
          if (cityIndex === -1) {
            this.locations[locationIndex].cities.push({id: location.id, name: location.name})
          }
        }

        for (const location of this.locations) {
          location.cities.sort((a, b) => a.name.localeCompare(b.name))
        }
        this.locations.sort((a, b) => a.name.localeCompare(b.name))

        for (const marketCountryWork of partner.marketsCountry) {
          const countryIndex = this.marketsCountry.findIndex((c) => c.id === marketCountryWork.id)
          if (countryIndex === -1) {
            this.marketsCountry.push(marketCountryWork)
          }
        }
        this.marketsCountry.sort((a, b) => a.name.localeCompare(b.name))

        const coreSkillIndex = this.coreSkills.findIndex((c) => c.id === partner.coreSkill.id)
        if (coreSkillIndex === -1) {
          this.coreSkills.push(partner.coreSkill)
        }
        this.coreSkills.sort((a, b) => a.name.localeCompare(b.name))

        for (const detailledSkills of partner.detailledSkills) {
          const dettailledSkillIndex = this.detailledSkills.findIndex((d) => d.id === detailledSkills.id)
          if (dettailledSkillIndex === -1) {
            this.detailledSkills.push(detailledSkills)
          }
          this.detailledSkills.sort((a, b) => a.name.localeCompare(b.name))
        }

        for (const expertServices of partner.expertServicePortfolios) {
          const expertServicesIndex = this.expertServices.findIndex((e) => e.id === expertServices.id)
          if (expertServicesIndex === -1) {
            this.expertServices.push(expertServices)
          }
          this.expertServices.sort((a, b) => a.name.localeCompare(b.name))
        }

        for (const sectorExperiences of partner.sectorExperiences) {
          const experienceSectorsIndex = this.experienceSectors.findIndex((e) => e.id === sectorExperiences.id)
          if (experienceSectorsIndex === -1) {
            this.experienceSectors.push(sectorExperiences)
          }
          this.experienceSectors.sort((a, b) => a.name.localeCompare(b.name))
        }

        for (const hiringSectors of partner.hiringSectors) {
          const hiringSectorsIndex = this.hiringSectors.findIndex((h) => h.id === hiringSectors.id)
          if (hiringSectorsIndex === -1) {
            this.hiringSectors.push(hiringSectors)
          }
          this.hiringSectors.sort((a, b) => a.name.localeCompare(b.name))
        }
      }
    },
    resetFilters () {
      this.marketsWorkedQuery = ''
      this.locationQuery = ''
      this.detailledSkillsQuery = ''
      this.portfolioOfServicesQuery = ''
      this.coreSkillQuery = ''
      this.sectorExperiencesQuery = ''
      this.hiringSectorsQuery = ''
      this.searchQuery = ''
      this.fetchPartners()
    },
    resetAdvancedFilters () {
      this.searchQuery = ''
      this.marketsWorkedQuery = ''
      this.coreSkillQuery = ''
      this.sectorExperiencesQuery = ''
      this.hiringSectorsQuery = ''
      this.fetchPartners()
    },
    advancedSearch () {
      this.isAdvancedSearch = !this.isAdvancedSearch
      if (!this.isAdvancedSearch) {
        this.$nextTick(this.resetAdvancedFilters)
      }
    },
    fetchPartners (withLoading) {
      const payload = {
        pagination: false
      }

      if (withLoading) {
        this.$vs.loading({
          background: 'primary'
        })
      }

      this.$store.dispatch('partner/fetchPartners', payload).then(() => {
        this.resetLocalPartners()
        this.executeFilters(false)

        if (withLoading) {
          this.$vs.loading.close()
        }
      })
    },
    confirmDeletePartner (partner) {
      this.partnerIdToDelete = partner.id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete Partner',
        text: `Do you want to delete ${partner.name}?`,
        accept: this.deletePartner,
        acceptText: this.$t('administration.userlist.table.actions.delete.dialog.acceptText'),
        cancelText: this.$t('administration.userlist.table.actions.delete.dialog.cancelText')
      })
    },
    deletePartner () {
      this.$store.dispatch('partner/deletePartner', this.partnerIdToDelete).then(() => {
        this.fetchPartners()
      })
    },
    locationRender (locations) {
      const returnLocation = []
      locations.forEach((location, i) => {
        returnLocation[i] = {
          country: location.country.name,
          cities: [location.name]
        }
      })
      const arr = Object.values(returnLocation.reduce((prev, curr) => {
        const { country, cities = [] } = curr
        const [city] = cities
        if (prev[country] && city) {
          prev[country].cities.push(city)
        } else {
          prev[country] = { country, cities: city ? [city] : [] }
        }
        return prev
      }, {}))
      arr.sort((a, b) => a.country.localeCompare(b.country))
      return arr
    },
    async addFilterOnCoreSkillSearch (id) {
      if (!this.coreSkillQuery.includes(id)) {
        this.isAdvancedSearch = true
        await this.$nextTick()
        this.coreSkillQuery = id
      } else {
        this.coreSkillQuery = ''
      }
    },
    addFilterOnDetailledSkillsSearch (id) {
      if (!this.detailledSkillsQuery.includes(id)) {
        this.detailledSkillsQuery = [...this.detailledSkillsQuery]
        this.detailledSkillsQuery.push(id)
      } else {
        const detailledSkillsQueryIndex = this.detailledSkillsQuery.findIndex(el => el === id)
        this.detailledSkillsQuery.splice(detailledSkillsQueryIndex, 1)
      }
    }
  },
  created () {
    this.fetchPartners(true)
  }
}
</script>

<style lang="scss" scoped>
.page-home {
  margin-top: -3em;
}

.contact a {
  color: black;
  &:hover {
    color: rgba(var(--vs-primary), 1);
  }
}
.btn-reset-filters {
  background: transparent;
  border: none;
  outline: none;
  color: rgba(var(--vs-primary), 1);
}
.clear-icon {
  font-size: 16px;
  color: rgba(var(--vs-primary), 1);;
}
.card-category {
  @apply mt-2 mx-3;
}
.presentation-item {
  @apply flex flex-col items-center text-center;
  &:hover > p {
    text-decoration: underline;
  }
}
</style>
