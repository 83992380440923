import modulePartner from './modulePartner'

export default {
  created () {
    if (!modulePartner.isRegistered) {
      this.$store.registerModule('partner', modulePartner)
      modulePartner.isRegistered = true
    }
  }
}
